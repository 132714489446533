import {Divider, Form, Input, message, Modal, Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {addFollowLog, AddLogReq, addSpendLog} from "../../api/modules/customer";
import {UserRole} from "../../const/user-role";
import {RootContext} from "../root/root";

const {TextArea} = Input;

type FieldType = {
	customerId: number;
	logType: "follow" | "spend";
	remark: string;
}

export interface AddLogModalInit {
	customerId: number;
}

const defaultInitialValues: AddLogModalInit = {
	customerId: 0,
}

export default function AddLogModal(
	{
		showModal,
		onCancel,
		initialValues
	}: {
		showModal: boolean;
		onCancel: () => void;
		initialValues: AddLogModalInit;
	}
) {
	const {currentUser} = useContext(RootContext);

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [form] = useForm<FieldType>();

	useEffect(() => {
		if (showModal) {
			form.setFieldsValue(initialValues || defaultInitialValues);
		}
	}, [showModal, initialValues, form]);

	const onOk = async () => {
		try {
			const values = await form.validateFields();

			const params: AddLogReq = {
				customerId: values.customerId,
				remark: values.remark
			}

			setConfirmLoading(true);
			if (values.logType === 'follow') {
				addFollowLog(params)
					.then((_) => {
						message.success("添加记录成功");
						form.resetFields();
						onCancel();
					})
					.catch(error => {
						message.warning("添加记录失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					})
			} else {
				addSpendLog(params)
					.then((_) => {
						message.success("添加记录成功");
						form.resetFields();
						onCancel();
					})
					.catch(error => {
						message.warning("添加记录失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					})
			}

		} catch (error) {
			console.log(error);
		}
	}

	const [logTypeOptions, setLogTypeOptions] = useState<any>([]);
	useEffect(() => {
		if (currentUser.userRole === UserRole.ADMIN) {
			setLogTypeOptions([
				{
					"label": "跟进记录",
					"value": "follow"
				},
				{
					"label": "投放情况",
					"value": "spend"
				},
			]);
		} else if (currentUser.userRole === UserRole.MEDIA_MANAGER || currentUser.userRole === UserRole.BIZ_MANAGER) {
			setLogTypeOptions([
				{
					"label": "跟进记录",
					"value": "follow"
				}
			]);
		} else if (currentUser.userRole === UserRole.ADV_BUYER) {
			setLogTypeOptions([
				{
					"label": "投放情况",
					"value": "spend"
				},
			]);
		}
	}, [currentUser]);

	return (
		<Modal
			title="填写记录"
			open={showModal}
			onOk={onOk}
			onCancel={onCancel}
			confirmLoading={confirmLoading}
			destroyOnClose={true}
			width={528}
		>
			<Divider/>
			<Form
				form={form}
				layout={"vertical"}
				initialValues={{
					...initialValues,
					logType: currentUser.userRole !== UserRole.ADV_BUYER ? "follow" : "spend",
				}}
			>
				<Form.Item<FieldType>
					name="customerId"
					rules={[{required: true, message: "请填写客户id"}]}
					style={{display: 'none'}}
				>
					<Input/>
				</Form.Item>
				<Form.Item<FieldType>
					label={"记录类型"}
					name={"logType"}
					rules={[{required: true, message: "请选择记录类型"}]}
				>
					<Select
						options={logTypeOptions}
					/>
				</Form.Item>
				<Form.Item<FieldType>
					label={"内容"}
					name={"remark"}
					rules={[{required: true, message: "请填写内容"}]}
				>
					<TextArea/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

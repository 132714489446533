export enum Placement {
	TIKTOK_SHOP_EC = 'TIKTOK_SHOP_EC',
	TIKTOK_SHOP_EC_NEW = 'TIKTOK_SHOP_EC_NEW',
	INDEPENDENT_EC = 'INDEPENDENT_EC',
	PLATFORM_EC = 'PLATFORM_EC',
	LIVE_STREAMING = 'LIVE_STREAMING',
	AFFILIATE_MARKETING = 'AFFILIATE_MARKETING',
	MEDIUM_LENGTH_VIDEOS = 'MEDIUM_LENGTH_VIDEOS',
	LEAD_FORM = 'LEAD_FORM',
	PRIVATE_DOMAIN_TRAFFIC = 'PRIVATE_DOMAIN_TRAFFIC',
	GAME_APP = 'GAME_APP',
	APPLICATION_APP = 'APPLICATION_APP',
	SHORT_DRAMA = 'SHORT_DRAMA',
}


export const placementToChinese = (value: Placement) => {
	switch (value) {
		case Placement.TIKTOK_SHOP_EC:
			return 'TikTok Shop电商';
		case Placement.TIKTOK_SHOP_EC_NEW:
			return 'TikTok Shop电商-新客';
		case Placement.INDEPENDENT_EC:
			return '独立站电商';
		case Placement.PLATFORM_EC:
			return '平台电商';
		case Placement.LIVE_STREAMING:
			return '直播';
		case Placement.AFFILIATE_MARKETING:
			return '网盟';
		case Placement.MEDIUM_LENGTH_VIDEOS:
			return '中视频';
		case Placement.LEAD_FORM:
			return '线索表单';
		case Placement.PRIVATE_DOMAIN_TRAFFIC:
			return '私域引流';
		case Placement.GAME_APP:
			return '游戏APP';
		case Placement.APPLICATION_APP:
			return '应用APP';
		case Placement.SHORT_DRAMA:
			return '短剧';
		default:
			return '未知';
	}
}
export const placementSelectOptions = Object.keys(Placement).map(key => {
	const item = Placement[key as keyof typeof Placement];
	return {
		label: placementToChinese(item),
		value: item
	}
});

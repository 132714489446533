export enum DashboardChartDataType {
	CUSTOMER_TYPE = 'CUSTOMER_TYPE',
	BIZ_STATUS = 'BIZ_STATUS',
	COOPERATION_STATUS = 'COOPERATION_STATUS',
	DIRECT_MANAGER = 'DIRECT_MANAGER',
	CUSTOMER_SOURCE = 'CUSTOMER_SOURCE',
}

export const dashboardChartDataTypeToChinese = (value: DashboardChartDataType) => {
	switch (value) {
		case DashboardChartDataType.CUSTOMER_TYPE:
			return '客户类型';
		case DashboardChartDataType.BIZ_STATUS:
			return '业务状态';
		case DashboardChartDataType.COOPERATION_STATUS:
			return '合作进度';
		case DashboardChartDataType.DIRECT_MANAGER:
			return '直客经理';
		case DashboardChartDataType.CUSTOMER_SOURCE:
			return '客户来源';
		default:
			return '未知';
	}
}

export const dashboardChartDataTypeSelectOptions = Object.keys(DashboardChartDataType).map(key => {
	const item = DashboardChartDataType[key as keyof typeof DashboardChartDataType];
	return {
		label: dashboardChartDataTypeToChinese(item),
		value: item
	}
});

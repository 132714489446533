export enum CooperationType {
	SELF = 'SELF',
	PROXY = 'PROXY',
}

export const cooperationTypeToChinese = (value: CooperationType) => {
	switch (value) {
		case CooperationType.SELF:
			return '自投';
		case CooperationType.PROXY:
			return '代投';
		default:
			return '未知';
	}
}

export const cooperationTypeSelectOptions = Object.keys(CooperationType).map(key => {
	const item = CooperationType[key as keyof typeof CooperationType];
	return {
		label: cooperationTypeToChinese(item),
		value: item
	}
});

export function cooperationTypeFromString(key: string): CooperationType {
	return CooperationType[key as keyof typeof CooperationType];
}

import styles from './user.module.scss';
import {Button, Flex, Form, Input, Select, Space} from "antd";
import React, {useRef, useState} from "react";
import {ActionType, ProColumns, ProTable} from "@ant-design/pro-components";
import {UserRole, userRoleSelectOptions, userRoleToChinese} from "../../../const/user-role";
import {useForm} from "antd/es/form/Form";
import {listUser, ListUserReq, ListUserRes} from "../../../api/modules/user";
import UserModal, {UserModalModalInit} from "./user-modal";

type SearchField = {
	username?: string;
	email?: string;
	role?: UserRole;
};

interface UserRow {
	key: React.Key,
	username: string;
	email: string;
	role: UserRole;
}

export default function User() {

	const tableRef = useRef<ActionType>();

	const [searchForm] = useForm<SearchField>();
	const [searchParams, setSearchParams] = useState<ListUserReq>({})
	const handleSearch = () => {
		const values = searchForm.getFieldsValue();
		console.log('Success:', values);

		const params: ListUserReq = {
			username: values.username,
			email: values.email,
			role: values.role,
		}
		setSearchParams(params);
		tableRef.current?.reload();
	};
	const handleSearchReset = () => {
		console.log('Reset');
		searchForm.resetFields();
		handleSearch();
	};

	const [modalInitialValues, setModalInitialValues] = useState<UserModalModalInit | undefined>(undefined);
	const [showModal, setShowModal] = React.useState(false);
	const [modalType, setModalType] = React.useState<'add' | 'update'>('add');
	const handleAdd = () => {
		setModalInitialValues(undefined);
		setModalType('add');
		setShowModal(true);
	}
	const handleUpdate = (record: UserRow) => {
		setModalInitialValues({
			id: Number(record.key),
			username: record.username,
			email: record.email,
			password: '********',
			role: record.role,
		});
		setModalType('update');
		setShowModal(true);
	}
	const handleCancel = () => {
		setShowModal(false)
		setModalInitialValues(undefined);
	}

	const columns: ProColumns<UserRow>[] = [
		{
			title: '用户名称',
			key: 'username',
			dataIndex: 'username',
			width: '20%',
		},
		{
			title: '邮箱',
			key: 'email',
			dataIndex: 'email',
			width: '40%',
		},
		{
			title: '角色',
			key: 'role',
			width: '20%',
			render(_, record) {
				return userRoleToChinese(record.role);
			}
		},
		{
			title: '操作',
			key: 'action',
			width: '20%',
			render: (_, record) => (
				<Flex align="center" gap="small">
					<Button type={"link"} size={"small"} onClick={() => {
						handleUpdate(record);
					}}>
						编辑
					</Button>
				</Flex>
			)
		}
	]

	return (
		<div className={styles.container}>
			<div className={styles.filterContainer}>
				<Form
					name="basic"
					layout="inline"
					size="middle"
					form={searchForm}
				>
					<Form.Item<SearchField> label="用户名" name="username" style={{width: 300}}>
						<Input placeholder="请输入" allowClear={true}/>
					</Form.Item>

					<Form.Item<SearchField> label="邮箱" name="email" style={{width: 300, marginLeft: 8}}>
						<Input placeholder="请输入" allowClear={true}/>
					</Form.Item>

					<Form.Item<SearchField> label="用户角色" name="role" style={{width: 300, marginLeft: 8}}>
						<Select placeholder="请选择" options={userRoleSelectOptions} allowClear={true}/>
					</Form.Item>

					<Form.Item style={{marginLeft: "auto"}}>
						<Space>
							<Button type="primary" onClick={handleSearch}>查询</Button>
							<Button onClick={handleSearchReset}>重置</Button>
						</Space>
					</Form.Item>
				</Form>
			</div>
			<div className={styles.tableContainer}>
				<ProTable
					columns={columns}
					search={false}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: [10, 20, 100],
					}}
					headerTitle={
						<Flex align="center" gap="middle">
							<Button type="primary" onClick={handleAdd}>
								新增
							</Button>
						</Flex>
					}
					request={async (params, sort, filter) => {
						let res: ListUserRes = await listUser({
							...searchParams,
							pageNumber: params.current ? params.current - 1 : 0,
							pageSize: params.pageSize,
						});

						const data: UserRow[] = res.items.map(item => ({
							key: item.id,
							username: item.username,
							email: item.email,
							role: item.role
						}));
						return {
							data: data,
							success: true,
							total: res.totalCount,
						};
					}}
					actionRef={tableRef}
				/>
			</div>
			<UserModal
				showModal={showModal}
				onCancel={handleCancel}
				modalType={modalType}
				initialValues={modalInitialValues}
				tableRef={tableRef}
			/>
		</div>
	)
}

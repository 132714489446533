export enum CustomerType {
	S = 'S',
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D'
}

export const customerTypeToChinese = (value: CustomerType) => {
	switch (value) {
		case CustomerType.S:
			return 'S类重点客户';
		case CustomerType.A:
			return 'A类优质客户';
		case CustomerType.B:
			return 'B类增长客户';
		case CustomerType.C:
			return 'C类潜力客户';
		case CustomerType.D:
			return 'D类沉默客户';
		default:
			return '未知';
	}
}

export const customerTypeSelectOptions = Object.keys(CustomerType).map(key => {
	const item = CustomerType[key as keyof typeof CustomerType];
	return {
		label: customerTypeToChinese(item),
		value: item
	}
})

export function customerTypeFromString(key: string): CustomerType {
	return CustomerType[key as keyof typeof CustomerType];
}

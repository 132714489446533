export enum BizStatus {
	PENDING = 'PENDING',
	STARTED = 'STARTED',
	PAUSED = 'PAUSED',
}

export const bizStatusToChinese = (value: BizStatus) => {
	switch (value) {
		case BizStatus.PENDING:
			return '待启动';
		case BizStatus.STARTED:
			return '已启动';
		case BizStatus.PAUSED:
			return '暂无/停止计划';
		default:
			return '未知';
	}
}

export const bizStatusSelectOptions = Object.keys(BizStatus).map(key => {
	const item = BizStatus[key as keyof typeof BizStatus];
	return {
		label: bizStatusToChinese(item),
		value: item
	}
});

export function bizStatusFromString(key: string): BizStatus {
	return BizStatus[key as keyof typeof BizStatus];
}

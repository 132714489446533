import styles from './customer-source.module.scss';
import {Button, Flex, Form, Input, Space} from "antd";
import React, {useRef, useState} from "react";
import {ActionType, ProColumns, ProTable} from "@ant-design/pro-components";
import {useForm} from "antd/es/form/Form";
import {listCustomerSource, ListCustomerSourceReq, ListCustomerSourceRes} from "../../../api/modules/customer-source";
import CustomerSourceModal, {CustomerSourceModalInit} from "./customer-source-modal";

type SearchField = {
	name?: string;
};

interface CustomerSourceRow {
	key: React.Key;
	name: string;
}

export default function CustomerSource() {

	const tableRef = useRef<ActionType>();

	const [searchForm] = useForm<SearchField>();
	const [searchParams, setSearchParams] = useState<ListCustomerSourceReq>({})
	const handleSearch = () => {
		const values = searchForm.getFieldsValue();
		console.log('Success:', values);

		const params: ListCustomerSourceReq = {
			name: values.name,
		}
		setSearchParams(params);
		tableRef.current?.reload();
	};
	const handleSearchReset = () => {
		console.log('Reset');
		searchForm.resetFields();
		handleSearch();
	};

	const [modalInitialValues, setModalInitialValues] = useState<CustomerSourceModalInit | undefined>(undefined);
	const [showModal, setShowModal] = React.useState(false);
	const [modalType, setModalType] = React.useState<'add' | 'update'>('add');
	const handleAddCustomerSource = () => {
		setModalInitialValues(undefined);
		setModalType('add');
		setShowModal(true);
	}
	const handleUpdateCustomerSource = (record: CustomerSourceRow) => {
		setModalInitialValues({
			id: Number(record.key),
			name: record.name,
		});
		setModalType('update');
		setShowModal(true);
	}
	const handleCancelCustomerSource = () => {
		setShowModal(false)
		setModalInitialValues(undefined);
	}

	const columns: ProColumns<CustomerSourceRow>[] = [
		{
			title: '客户来源',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: '操作',
			key: 'action',
			width: '20%',
			render: (_, record) => (
				<Flex align="center" gap="small">
					<Button type={"link"} size={"small"} onClick={() => {
						handleUpdateCustomerSource(record);
					}}>
						编辑
					</Button>
				</Flex>
			)
		}
	]

	return (
		<div className={styles.container}>
			<div className={styles.filterContainer}>
				<Form
					name="basic"
					layout="inline"
					size="middle"
					form={searchForm}
				>
					<Form.Item<SearchField>
						label="客户来源"
						name="name"
						style={{width: 300}}
					>
						<Input placeholder="请输入客户来源" allowClear={true}/>
					</Form.Item>

					<Form.Item style={{marginLeft: "auto"}}>
						<Space>
							<Button type="primary" onClick={handleSearch}>查询</Button>
							<Button onClick={handleSearchReset}>重置</Button>
						</Space>
					</Form.Item>
				</Form>
			</div>
			<div className={styles.tableContainer}>
				<ProTable
					columns={columns}
					search={false}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: [10, 20, 100],
					}}
					headerTitle={
						<Flex align="center" gap="middle">
							<Button type="primary" onClick={handleAddCustomerSource}>
								新增
							</Button>
						</Flex>
					}
					request={async (params, sort, filter) => {
						let res: ListCustomerSourceRes = await listCustomerSource({
							...searchParams,
							pageNumber: params.current ? params.current - 1 : 0,
							pageSize: params.pageSize,
						});

						const data: CustomerSourceRow[] = res.items.map(item => ({
							key: item.id,
							name: item.name,
						}));
						return {
							data: data,
							success: true,
							total: res.totalCount,
						};
					}}
					actionRef={tableRef}
				/>
			</div>
			<CustomerSourceModal
				showModal={showModal}
				onCancel={handleCancelCustomerSource}
				modalType={modalType}
				initialValues={modalInitialValues}
				tableRef={tableRef}
			/>
		</div>
	)
}

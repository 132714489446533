import axiosInstance from "../axios";

export interface ListCustomerSourceReq {
	name?: string,

	pageNumber?: number,
	pageSize?: number,
}

interface CustomerSourceItem {
	id: number,
	name: string
}

export interface ListCustomerSourceRes {
	items: CustomerSourceItem[],
	totalCount: number
}


export const listCustomerSource = async (params: ListCustomerSourceReq): Promise<ListCustomerSourceRes> => {

	const response = await axiosInstance.get<ListCustomerSourceRes>(
		'/customer_source/list',
		{
			params
		}
	)
	return response.data;
}


export interface AddCustomerSourceReq {
	name: string,
}

export const addCustomerSource = async (params: AddCustomerSourceReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer_source/add',
		params
	)
	return response.data;
}

export interface UpdateCustomerSourceReq {
	id: number,
	name: string,
}

export const updateCustomerSource = async (params: UpdateCustomerSourceReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/customer_source/update',
		params
	)
	return response.data;
}

export enum CooperationStatus {
	PENDING = 'PENDING',
	FAILED = 'FAILED',
	NO_BC = 'NO_BC',
	NO_CHARGE = 'NO_CHARGE',
	NO_SPEND = 'NO_SPEND',
	SPENDING = 'SPENDING',
	SPEND_ABORT = 'SPEND_ABORT',
	END = 'END',
}

export const cooperationStatusToChinese = (value: CooperationStatus) => {
	switch (value) {
		case CooperationStatus.PENDING:
			return '开户中';
		case CooperationStatus.FAILED:
			return '开户失败';
		case CooperationStatus.NO_BC:
			return '未绑定BC';
		case CooperationStatus.NO_CHARGE:
			return '未充值';
		case CooperationStatus.NO_SPEND:
			return '未消耗';
		case CooperationStatus.SPENDING:
			return '消耗中';
		case CooperationStatus.SPEND_ABORT:
			return '消耗中断';
		case CooperationStatus.END:
			return '结束合作';
		default:
			return '未知';
	}
}

export const cooperationStatusSelectOptions = Object.keys(CooperationStatus).map(key => {
	const item = CooperationStatus[key as keyof typeof CooperationStatus];
	return {
		label: cooperationStatusToChinese(item),
		value: item
	}
});

export function cooperationStatusFromString(key: string): CooperationStatus {
	return CooperationStatus[key as keyof typeof CooperationStatus];
}


import axiosInstance from "./axios";
import {DashboardChartDataType} from "../const/dashboard-chart-data-type";

export interface CustomerInfoReq {
	cooperationTimeBegin?: string,
	cooperationTimeEnd?: string
}

export interface CustomerInfoRes {
	total: number,
	spending: number,
	pending: number
}

export const getCustomerInfo = async (params: CustomerInfoReq): Promise<CustomerInfoRes> => {
	console.log("Getting customer information:", params);

	const response = await axiosInstance.get<CustomerInfoRes>(
		'/dashboard/get_customer_info',
		{
			params
		}
	)
	return response.data;
}

export interface ChartDataReq {
	type: DashboardChartDataType
	bizManagerId?: number,
	cooperationTimeBegin?: string
	cooperationTimeEnd?: string
}

export interface ChartDataItem {
	name: string,
	count: number
}

export interface ChartDataRes {
	items: ChartDataItem[]
}

export const getChartData = async (params: ChartDataReq): Promise<ChartDataRes> => {
	console.log("Getting chart data:", params);

	const response = await axiosInstance.get<ChartDataRes>(
		'/dashboard/get_chart_data',
		{
			params
		}
	)
	return response.data;
}

import {Divider, Form, Input, message, Modal, Select} from "antd";
import styles from "./user-modal.module.scss";
import React, {useEffect, useState} from "react";
import {ActionType} from "@ant-design/pro-table/es/typing";
import {useForm} from "antd/es/form/Form";
import {UserRole, userRoleSelectOptions} from "../../../const/user-role";
import {addUser, AddUserReq, updateUser, UpdateUserReq} from "../../../api/modules/user";
import Password from "antd/es/input/Password";

type FormField = {
	id: number;
	username: string;
	email: string;
	password?: string;
	role: UserRole;
};

export interface UserModalModalInit {
	id: number;
	username: string;
	email: string;
	password: string;
	role: UserRole;
}

const defaultInitialValues: UserModalModalInit = {
	id: 0,
	username: '',
	email: '',
	password: '',
	role: UserRole.ADMIN,
}

export default function UserModal(
	{
		showModal,
		onCancel,
		modalType,
		initialValues,
		tableRef
	}: {
		showModal: boolean;
		onCancel: () => void;
		modalType: 'add' | 'update';
		initialValues?: UserModalModalInit;
		tableRef: React.MutableRefObject<ActionType | undefined>;
	}
) {

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [form] = useForm<FormField>();

	const onOk = async () => {
		try {
			const values = await form.validateFields();
			console.log("Form values: ", values);

			setConfirmLoading(true);
			if (modalType === 'add') {
				let params: AddUserReq = {
					username: values.username,
					email: values.email,
					password: values.password!,
					role: values.role,
				}
				addUser(params)
					.then((_) => {
						message.success('添加用户成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("添加用户失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});

			} else {
				const params: UpdateUserReq = {
					id: values.id,
					username: values.username,
					email: values.email,
					role: values.role,
				}
				updateUser(params)
					.then((_) => {
						message.success('编辑用户成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("编辑用户失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});
			}

		} catch (error) {
			console.log("Validation failed:", error);
		}
	}

	useEffect(() => {
		if (showModal) {
			form.setFieldsValue(initialValues || defaultInitialValues);
		}
	}, [showModal, initialValues, form]);

	return (
		<Modal
			title={modalType === 'add' ? '添加用户' : '编辑用户'}
			open={showModal}
			onOk={onOk}
			onCancel={onCancel}
			confirmLoading={confirmLoading}
			destroyOnClose={true}
			width={528}
		>
			<Divider/>
			<div className={styles.container}>
				<Form
					form={form}
					layout={"vertical"}
					initialValues={initialValues}
				>
					<Form.Item<FormField>
						name="id"
						style={{display: 'none'}}
					>
						<Input/>
					</Form.Item>
					<Form.Item<FormField>
						label="用户名称"
						name="username"
						rules={[{required: true, message: "请输入用户名称"}]}
					>
						<Input/>
					</Form.Item>
					<Form.Item<FormField>
						label="邮箱"
						name="email"
						rules={[{required: true, message: "请输入邮箱"}]}
					>
						<Input/>
					</Form.Item>
					{modalType === 'add' && (
						<Form.Item<FormField>
							label="密码"
							name="password"
							rules={[{required: true, message: "请输入密码"}]}
						>
							<Password/>
						</Form.Item>
					)}
					<Form.Item<FormField>
						label="角色"
						name="role"
						rules={[{required: true, message: "请选择角色"}]}
					>
						<Select options={userRoleSelectOptions}/>
					</Form.Item>
				</Form>
			</div>
			<Divider/>
		</Modal>
	)
}

import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function Unauthorized() {
	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate('/dashboard'); // 跳转到目标页面
		}, 3000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<div>该页面仅管理员可访问，3秒后将跳转至首页...</div>
	)
}

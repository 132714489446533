import {DatePicker, Form, message, Select} from "antd";
import styles from "./dashboard.module.scss";
import {Pie} from "@ant-design/charts";
import {useEffect, useState} from "react";
import {ChartDataReq, CustomerInfoReq, CustomerInfoRes, getChartData, getCustomerInfo} from "../../api/dashboard";
import {Dayjs} from "dayjs";
import {listUser} from "../../api/modules/user";
import {UserRole} from "../../const/user-role";
import {DashboardChartDataType, dashboardChartDataTypeSelectOptions} from "../../const/dashboard-chart-data-type";
import {customerTypeFromString, customerTypeToChinese} from "../../const/customer-type";
import {bizStatusFromString, bizStatusToChinese} from "../../const/biz-status";
import {cooperationStatusFromString, cooperationStatusToChinese} from "../../const/cooperation-status";

const {RangePicker} = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface PieItem {
	type: string,
	value: number
}

interface CustomerInfoFilter {
	cooperationTime?: RangeValue;
}

interface ChartDataFilter {
	type: DashboardChartDataType,
	bizManagerId?: number,
	cooperationTime?: RangeValue;
}

export default function Dashboard() {
	const [customerInfoFilter, setCustomerInfoFilter] = useState<CustomerInfoFilter>({});
	const [customerInfo, setCustomerInfo] = useState<CustomerInfoRes | null>(null)
	useEffect(() => {
		console.log("customerInfoFilter", customerInfoFilter);

		const params: CustomerInfoReq = {
			cooperationTimeBegin: customerInfoFilter.cooperationTime ? customerInfoFilter.cooperationTime[0]!.format("YYYY-MM-DD HH:mm:ss") : undefined,
			cooperationTimeEnd: customerInfoFilter.cooperationTime ? customerInfoFilter.cooperationTime[1]!.format("YYYY-MM-DD HH:mm:ss") : undefined,
		}

		getCustomerInfo(params)
			.then((response) => {
				setCustomerInfo(response);
			})
			.catch(error => {
				message.warning(error);
			});
	}, [customerInfoFilter]);

	const [chartDataFilter, setChartDataFilter] = useState<ChartDataFilter>({type: DashboardChartDataType.CUSTOMER_TYPE});
	const [chartData, setChartData] = useState<PieItem[]>([]);
	useEffect(() => {
		const params: ChartDataReq = {
			type: chartDataFilter.type,
			bizManagerId: chartDataFilter.bizManagerId,
			cooperationTimeBegin: chartDataFilter.cooperationTime ? chartDataFilter.cooperationTime[0]!.format("YYYY-MM-DD HH:mm:ss") : undefined,
			cooperationTimeEnd: chartDataFilter.cooperationTime ? chartDataFilter.cooperationTime[1]!.format("YYYY-MM-DD HH:mm:ss") : undefined,
		}

		getChartData(params)
			.then((response) => {
				const chartItems = response.items.map(item => {
					let type = '';
					if (item.name === null) {
						type = '未知';
					} else {
						switch (params.type) {
							case DashboardChartDataType.CUSTOMER_TYPE:
								type = customerTypeToChinese(customerTypeFromString(item.name));
								break;
							case DashboardChartDataType.BIZ_STATUS:
								type = bizStatusToChinese(bizStatusFromString(item.name));
								break;
							case DashboardChartDataType.COOPERATION_STATUS:
								type = cooperationStatusToChinese(cooperationStatusFromString(item.name));
								break;
							default:
								type = item.name;
						}
					}

					return {
						type: type,
						value: item.count,
					}
				})
				setChartData(chartItems);
			})
			.catch(error => {
				message.warning("加载图表失败：" + error.message);
			})
	}, [chartDataFilter]);

	const total = chartData.reduce((sum, item) => sum + item.value, 0);

	const config = {
		data: chartData,
		angleField: 'value',
		colorField: 'type',
		label: {
			text: (item: { type: string, value: number }) => `${item.type}  ${(item.value / total * 100).toFixed(0)}%`,
			position: 'outside',
		},
		legend: null,
		height: 300,
		radius: 0.9
	};

	const [bizManagerOptions, setBizManagerOptions] = useState<{
		label: string,
		value: number
	}[]>([]);
	useEffect(() => {
		listUser({
			role: UserRole.BIZ_MANAGER
		}).then((response) => {
			setBizManagerOptions(response.items.map(item => {
				return {
					label: item.username,
					value: item.id,
				}
			}))
		})
	}, []);

	return (
		<div className={styles.content}>
			<div className={styles.customer}>
				<div className={styles.cardHeader}>
					<div className={styles.cardTitle}>
						<div className={styles.block}/>
						<div className={styles.cardTitleText}>客户数据</div>
					</div>
					<div className={styles.filter}>
						<Form layout={"inline"} colon={false}>
							<Form.Item label={"合作时间"}>
								<RangePicker
									placeholder={['请选择', '请选择']}
									value={customerInfoFilter.cooperationTime}
									onChange={(value) => {
										let newFilter = {
											...customerInfoFilter,
											cooperationTime: value,
										};
										setCustomerInfoFilter(newFilter);
									}}/>
							</Form.Item>
						</Form>
					</div>
				</div>
				<div className={styles.data}>
					<div className={styles.dataItem}>
						<span className={styles.dataTitle}>客户总数</span>
						<span className={styles.count}>{customerInfo != null ? customerInfo.total : 0}</span>
					</div>
					<div className={styles.dataItem}>
						<span className={styles.dataTitle}>消耗中客户数</span>
						<span className={styles.count}>{customerInfo != null ? customerInfo.spending : 0}</span>
					</div>
					<div className={styles.dataItem}>
						<span className={styles.dataTitle}>待启动客户数</span>
						<span className={styles.count}>{customerInfo != null ? customerInfo.pending : 0}</span>
					</div>
				</div>
			</div>
			<div className={styles.chart}>
				<div className={styles.cardHeader}>
					<div className={styles.cardTitle}>
						<div className={styles.block}/>
						<div className={styles.cardTitleText}>数据图表</div>
					</div>
					<div className={styles.filter}>
						<Form layout={"inline"}>
							<Form.Item label={"图表字段"} colon={false}>
								<Select
									options={dashboardChartDataTypeSelectOptions}
									value={chartDataFilter.type}
									onChange={(value) => {
										const newChartDataFilter = {
											...chartDataFilter,
											type: value
										};
										setChartDataFilter(newChartDataFilter);
									}}
									style={{width: 120}}
								/>
							</Form.Item>
							<Form.Item label={"商务"} colon={false}>
								<Select
									placeholder="全部商务"
									allowClear={true}
									options={bizManagerOptions}
									value={chartDataFilter.bizManagerId}
									onChange={(value) => {
										const newChartDataFilter = {
											...chartDataFilter,
											bizManagerId: value
										};
										setChartDataFilter(newChartDataFilter);
									}}
									style={{width: 120}}
								/>
							</Form.Item>
							<Form.Item label={"合作时间"} colon={false}>
								<RangePicker
									placeholder={['请选择', '请选择']}
									value={chartDataFilter.cooperationTime}
									onChange={(value) => {
										let newFilter = {
											...chartDataFilter,
											cooperationTime: value
										};
										setChartDataFilter(newFilter);
									}}
								/>
							</Form.Item>
						</Form>
					</div>
				</div>
				<div className={styles.data}>
					<div className={styles.pieContainer}>
						{chartData.length > 0
							? <Pie {...config} />
							: <div>无数据</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

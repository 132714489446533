import {Button, Form, FormProps, Input, message} from "antd";
import styles from "./login.module.scss";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {login, LoginReq} from "../../api/login";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

type FieldType = {
	username?: string;
	password?: string;
};

export default function Login() {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);

	const onFinish: FormProps<FieldType>['onFinish'] = async () => {
		const values = await form.validateFields();

		setLoading(true);
		const params: LoginReq = {
			username: values.username,
			password: values.password,
		}
		login(params)
			.then(async (res) => {
				message.success("登录成功");
				localStorage.setItem("token", res.token);
				navigate("/dashboard")
			})
			.catch((error) => {
				console.log("登录失败: ", error);
				message.warning(error.message);
			})
			.finally(() => {
				setLoading(false);
			})
	}

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.title}>
					CRM管理系统
				</div>
				<div className={styles.loginContainer}>
					<div className={styles.subtitle}>
						欢迎登录
					</div>
					<div className={styles.formContainer}>
						<Form
							form={form}
							name="basic"
							wrapperCol={{offset: 2, span: 20}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Form.Item<FieldType>
								name="username"
								rules={[{required: true, message: '请输入用户名'}]}
							>
								<Input
									size="large"
									placeholder="用户名"
									prefix={<MailOutlined style={{marginRight: 10}}/>}
								/>
							</Form.Item>

							<Form.Item<FieldType>
								name="password"
								rules={[{required: true, message: '请输入密码'}]}
							>
								<Input.Password
									size={"large"}
									placeholder={"密码"}
									prefix={<LockOutlined style={{marginRight: 10}}/>}
								/>
							</Form.Item>

							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									style={{width: '100%', height: '40px'}}
								>
									登录
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

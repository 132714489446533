import {Divider, message, Modal, Timeline} from "antd";
import {ReactNode, useEffect, useState} from "react";
import {ListLogReq, listSpendLog} from "../../api/modules/customer";

export default function CheckSpendLogModal(
	{
		showModal,
		onCancel,
		customerId
	}: {
		showModal: boolean;
		onCancel: () => void;
		customerId: number;
	}
) {
	const [items, setItems] = useState<{
		label: ReactNode;
		children: ReactNode
	}[]>()

	useEffect(() => {
		if (customerId === 0) {
			return;
		}

		const params: ListLogReq = {
			customerId: customerId
		}

		listSpendLog(params)
			.then((response) => {
				setItems(response.items.reverse().map(item => ({
					label: <div style={{marginRight: 10}}>{item.logTime}</div>,
					children: (
						<div style={{width: 360, display: "flex"}}>
							<div style={{width: 40}}>
								{item.username}
							</div>
							<div style={{width: 300, marginLeft: 20}}>
								{item.remark}
							</div>
						</div>
					)
				})))
			})
			.catch(error => {
				console.log(error);
				message.warning("获取投放情况失败：" + error.message);
			})
	}, [customerId]);

	return (
		<Modal
			title="投放情况"
			open={showModal}
			onOk={onCancel}
			onCancel={onCancel}
			destroyOnClose={true}
			width={613}
		>
			<Divider/>
			<div style={{height: 400, overflow: "auto", paddingTop: 20}}>
				<Timeline
					mode={'left'}
					items={items}
					style={{marginRight: 140, paddingRight: 20}}
				/>
			</div>
		</Modal>
	)
}

import {Divider, Form, Input, message, Modal} from "antd";
import styles from "./customer-source-modal.module.scss";
import React, {useEffect, useState} from "react";
import {ActionType} from "@ant-design/pro-table/es/typing";
import {useForm} from "antd/es/form/Form";
import {
	addCustomerSource,
	AddCustomerSourceReq,
	updateCustomerSource,
	UpdateCustomerSourceReq
} from "../../../api/modules/customer-source";

type FormField = {
	id: number;
	name: string;
};

export interface CustomerSourceModalInit {
	id: number;
	name: string;
}

const defaultInitialValues: CustomerSourceModalInit = {
	id: 0,
	name: '',
}

export default function CustomerSourceModal(
	{
		showModal,
		onCancel,
		modalType,
		initialValues,
		tableRef
	}: {
		showModal: boolean;
		onCancel: () => void;
		modalType: 'add' | 'update';
		initialValues?: CustomerSourceModalInit;
		tableRef: React.MutableRefObject<ActionType | undefined>;
	}
) {

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [form] = useForm<FormField>();

	const onOk = async () => {
		try {
			const values = await form.validateFields();
			console.log("Form values: ", values);

			setConfirmLoading(true);
			if (modalType === 'add') {
				let params: AddCustomerSourceReq = {
					name: values.name,
				}
				addCustomerSource(params)
					.then((_) => {
						message.success('添加客户来源成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("添加客户来源失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});
			} else {
				const params: UpdateCustomerSourceReq = {
					id: values.id,
					name: values.name,
				}
				updateCustomerSource(params)
					.then((_) => {
						message.success('编辑客户来源成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("编辑客户来源失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});
			}

		} catch (error) {
			console.log("Validation failed:", error);
		}
	}

	useEffect(() => {
		if (showModal) {
			form.setFieldsValue(initialValues || defaultInitialValues);
		}
	}, [showModal, initialValues, form]);

	return (
		<Modal
			title={modalType === 'add' ? '添加客户来源' : '编辑客户来源'}
			open={showModal}
			onOk={onOk}
			onCancel={onCancel}
			confirmLoading={confirmLoading}
			destroyOnClose={true}
			width={528}
		>
			<Divider/>
			<div className={styles.container}>
				<Form
					form={form}
					layout={"vertical"}
					initialValues={initialValues}
				>
					<Form.Item<FormField> name="id" style={{display: 'none'}}>
						<Input/>
					</Form.Item>
					<Form.Item<FormField>
						label="客户来源"
						name="name"
						rules={[{required: true, message: "请输入客户来源"}]}
					>
						<Input/>
					</Form.Item>
				</Form>
			</div>
			<Divider/>
		</Modal>
	)
}

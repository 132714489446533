export enum UserRole {
	ADMIN = 'ADMIN',
	BIZ_MANAGER = 'BIZ_MANAGER',
	MEDIA_MANAGER = 'MEDIA_MANAGER',
	ADV_BUYER = 'ADV_BUYER'
}

export const userRoleToChinese = (value: UserRole) => {
	switch (value) {
		case UserRole.ADMIN:
			return '管理员';
		case UserRole.BIZ_MANAGER:
			return '商务';
		case UserRole.MEDIA_MANAGER:
			return '媒介';
		case UserRole.ADV_BUYER:
			return '投手';
		default:
			return '未知';
	}
}
export const userRoleSelectOptions = Object.keys(UserRole).map(key => {
	const item = UserRole[key as keyof typeof UserRole];
	return {
		label: userRoleToChinese(item),
		value: item
	}
});

export function userRoleFromString(key: string): UserRole {
	return UserRole[key as keyof typeof UserRole];
}

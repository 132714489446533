import Sider from "antd/es/layout/Sider";
import styles from "./root.module.scss";
import {Button, Layout, Menu, MenuProps, message} from "antd";
import {PieChartOutlined, ProfileOutlined, UserOutlined} from "@ant-design/icons";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {createContext, useEffect, useState} from "react";
import {UserRole, userRoleFromString, userRoleToChinese} from "../../const/user-role";
import {CurrentUserRes, getCurrentUser} from "../../api/modules/user";


const layoutStyle = {
	minHeight: '100vh',
};

const siderStyle: React.CSSProperties = {
	backgroundColor: '#FFFFFF',
	boxShadow: '1px 0px 0px 0px rgba(0,0,0,0.06)'
};

const headerStyle: React.CSSProperties = {
	height: 48,
	padding: 0,
	backgroundColor: '#FFFFFF',
};

const contentStyle: React.CSSProperties = {
	height: "100%",
	backgroundColor: '#F6F8FC',
};


type MenuItem = Required<MenuProps>['items'][number];

interface CurrentUser {
	id: number;
	username: string;
	email: string;
	userRole: UserRole;
}

interface IRootContext {
	currentUser: CurrentUser;
}

export const RootContext = createContext<IRootContext>({
	currentUser: {
		id: 0,
		username: '',
		email: '',
		userRole: UserRole.ADMIN,
	}
});

export default function RootLayout() {
	const location = useLocation();
	const navigate = useNavigate();
	if (location.pathname === '/') {
		navigate('/dashboard');
	}

	const [currentUser, setCurrentUser] = useState<CurrentUser>({
		id: 0,
		username: '',
		email: '',
		userRole: UserRole.ADMIN,
	});
	useEffect(() => {
		const data = localStorage.getItem("currentUser");
		if (data !== null) {
			const userObj = JSON.parse(data);
			const user = {
				...userObj,
				userRole: userRoleFromString(userObj.userRole)
			};
			setCurrentUser(user);
		} else {
			getCurrentUser()
				.then((res: CurrentUserRes) => {
					setCurrentUser(res);
					localStorage.setItem('currentUser', JSON.stringify(res));
				})
				.catch((error) => {
					message.warning('获取当前用户失败: ' + error.message);
				})
		}
	}, []);

	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
	useEffect(() => {
		const items: MenuItem[] = [
			{
				key: '/dashboard',
				label: '首页总览',
				icon: <PieChartOutlined/>,
			},
			{
				key: '/customer',
				label: '客户管理',
				icon: <UserOutlined/>,
			}
		];
		if (currentUser.userRole === UserRole.ADMIN) {
			items.push({
				key: '/config',
				label: '信息配置',
				icon: <ProfileOutlined/>,
				children: [
					{
						key: '/config/customer_source',
						label: '客户来源',
					},
					{
						key: '/config/user',
						label: '用户管理',
					},
				]
			})
		}
		setMenuItems(items);
	}, [currentUser]);

	const onClick: MenuProps['onClick'] = (e) => {
		console.log('click ', e);
		navigate(e.key)
	};

	return (
		<Layout style={layoutStyle}>
			<Sider width={208} style={siderStyle}>
				<div className={styles.menuHeader}>
					CRM管理系统
				</div>
				<div className={styles.menuContainer}>
					<Menu
						mode="inline"
						onClick={onClick}
						defaultSelectedKeys={['/dashboard']}
						defaultOpenKeys={['/config']}
						selectedKeys={[location.pathname]}
						items={menuItems}
						style={{borderRight: 0}}
					/>
				</div>
			</Sider>
			<Layout>
				<div style={headerStyle}>
					<div className={styles.header}>
						<div className={styles.logout}>
							<Button onClick={() => {
								localStorage.removeItem("token");
								localStorage.removeItem('currentUser');
								navigate('/login');
							}}>退出登录</Button>
						</div>
						<div className={styles.user}>
							<div className={styles.username}>{currentUser.username}</div>
							<div className={styles.role}>{userRoleToChinese(currentUser.userRole)}</div>
						</div>
					</div>
				</div>
				<div style={contentStyle}>
					<RootContext.Provider value={{currentUser: currentUser}}>
						<Outlet/>
					</RootContext.Provider>
				</div>
			</Layout>
		</Layout>
	)
}

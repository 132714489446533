import styles from "./customer.module.scss";
import {Button, Col, DatePicker, Dropdown, Flex, Form, Input, message, Row, Select, Space, Table} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ActionType, ColumnsState, ProColumns, ProTable} from "@ant-design/pro-components";
import {AppstoreFilled} from "@ant-design/icons";
import {ColumnType} from "antd/lib/table";
import dayjs, {Dayjs} from "dayjs";
import CustomerInfoModal, {IModalCustomerInfo} from "./customer-info-modal";
import {
	listCustomer,
	ListCustomerReq,
	updateCompanyFirstAccountTime,
	UpdateCompanyFirstAccountTimeReq
} from "../../api/modules/customer";
import {CooperationType, cooperationTypeSelectOptions, cooperationTypeToChinese} from "../../const/cooperation-type";
import {Placement, placementSelectOptions, placementToChinese} from "../../const/placement";
import {CustomerType, customerTypeSelectOptions, customerTypeToChinese} from "../../const/customer-type";
import {CooperationStatus, cooperationStatusSelectOptions, cooperationStatusToChinese} from "../../const/cooperation-status";
import {BizStatus, bizStatusSelectOptions, bizStatusToChinese} from "../../const/biz-status";
import {useForm} from "antd/es/form/Form";
import {listCustomerSource} from "../../api/modules/customer-source";
import AddLogModal, {AddLogModalInit} from "./add-log-modal";
import CheckUpdateLogModal from "./check-update-log-modal";
import CheckFollowLogModal from "./check-follow-log-modal";
import CheckSpendLogModal from "./check-spend-log-modal";
import {UserRole} from "../../const/user-role";
import {RootContext} from "../root/root";
import * as XLSX from 'xlsx';

const {RangePicker} = DatePicker;


type SearchField = {
	customerNo?: string;
	companyName?: string;
	customerType?: CustomerType;
	cooperationStatus?: CooperationStatus;
	customerSource?: number;
	cooperationType?: CooperationType;
	placement?: Placement;
	bizStatus?: BizStatus;
	directManager?: string;
	cooperationTime?: Dayjs[];
	firstAccountTime?: Dayjs[];
};


interface CustomerCompanyRow {
	key: React.Key;
	name: string,
	firstAccountTime?: Dayjs
}

interface CustomerRow {
	key: React.Key;
	customerNo: string,
	bcNameList: string[],
	cooperationTypeList: CooperationType[],
	placementList: Placement[],
	customerType: CustomerType,
	cooperationStatus: CooperationStatus,
	bizStatus: BizStatus,
	cooperationTime?: Dayjs,
	accountPeriod: number,
	directManager: string,
	customerSource: {
		id: number,
		name: string
	},
	bizManager: {
		id: number,
		username: string
	},
	advBuyer?: {
		id: number,
		username: string
	},
	background?: string,
	companyList: CustomerCompanyRow[]
}

export default function Customer() {
	const {currentUser} = useContext(RootContext);

	const [columnState, setColumnState] = useState<Record<string, ColumnsState>>();
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
	const [selectedRows, setSelectedRows] = useState<CustomerRow[]>([]);
	const hasSelected = selectedKeys.length > 0;

	const [customerSourceOptions, setCustomerSourceOptions] = useState<{
		label: string,
		value: number
	}[]>([]);
	useEffect(() => {
		// 获取所有客户来源
		listCustomerSource({}).then((response) => {
			setCustomerSourceOptions(response.items.map(item => {
				return {
					label: item.name,
					value: item.id,
				}
			}))
		})
	}, []);

	const tableRef = useRef<ActionType>();
	const [currentPage, setCurrentPage] = useState(1);

	const [searchForm] = useForm<SearchField>();
	const [searchParams, setSearchParams] = useState<ListCustomerReq>({})
	const handleSearch = () => {
		const values = searchForm.getFieldsValue();
		console.log('Success:', values);

		const cooperationTime = values.cooperationTime;
		const firstAccountTime = values.firstAccountTime;
		const params: ListCustomerReq = {
			...values,
			customerSourceId: values.customerSource,
			cooperationTimeBegin: cooperationTime ? cooperationTime[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
			cooperationTimeEnd: cooperationTime ? cooperationTime[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
			firstAccountTimeBegin: firstAccountTime ? firstAccountTime[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
			firstAccountTimeEnd: firstAccountTime ? firstAccountTime[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
		}
		setSearchParams(params);
		setCurrentPage(1);
		tableRef.current?.reload();
	};
	const handleSearchReset = () => {
		console.log('Reset');
		searchForm.resetFields();
		handleSearch();
	};

	const [customerInfoInitialValues, setCustomerInfoInitialValues] = useState<IModalCustomerInfo | undefined>(undefined);
	const [showCustomerModal, setShowCustomerModal] = React.useState(false);
	const [customerModalType, setCustomerModalType] = React.useState<'add' | 'update'>('add');
	const handleAddCustomer = () => {
		setCustomerInfoInitialValues(undefined);
		setCustomerModalType('add');
		setShowCustomerModal(true);
	}
	const handleUpdateCustomer = (record: CustomerRow) => {
		setCustomerInfoInitialValues({
			customerId: Number(record.key),
			customerNo: record.customerNo,
			bcNameList: record.bcNameList,
			companyList: record.companyList.map(item => ({companyId: Number(item.key), companyName: item.name})),
			cooperationTypeList: record.cooperationTypeList,
			placementList: record.placementList ? record.placementList : undefined,
			customerType: record.customerType,
			directManager: record.directManager,
			customerSource: record.customerSource?.id,
			bizManager: record.bizManager?.id,
			advBuyer: record.advBuyer?.id,
			cooperationStatus: record.cooperationStatus,
			bizStatus: record.bizStatus,
			background: record.background
		});
		setCustomerModalType('update');
		setShowCustomerModal(true);
	}
	const handleCancelCustomerModal = () => {
		setShowCustomerModal(false)
		setCustomerInfoInitialValues(undefined);
	}

	const [addLogInitialValues, setAddLogInitialValues] = useState<AddLogModalInit>({customerId: 0});
	const [showAddLogModal, setShowAddLogModal] = React.useState(false);
	const handleAddLog = (record: CustomerRow) => {
		setAddLogInitialValues({
			customerId: Number(record.key),
		});
		setShowAddLogModal(true);
	}
	const handleCancelAddLogModal = () => {
		setShowAddLogModal(false)
		setAddLogInitialValues({customerId: 0});
	}

	const [checkUpdateLogCustomerId, setCheckUpdateLogCustomerId] = React.useState<number>(0);
	const [showCheckUpdateLogModal, setShowCheckUpdateLogModal] = React.useState(false);
	const handleCheckUpdateLog = (record: CustomerRow) => {
		setCheckUpdateLogCustomerId(Number(record.key));
		setShowCheckUpdateLogModal(true);
	}
	const handleCancelCheckUpdateLog = () => {
		setShowCheckUpdateLogModal(false)
		setCheckUpdateLogCustomerId(0);
	}

	const [checkFollowLogCustomerId, setCheckFollowLogCustomerId] = React.useState<number>(0);
	const [showCheckFollowLogModal, setShowCheckFollowLogModal] = React.useState(false);
	const handleCheckFollowLog = (record: CustomerRow) => {
		setCheckFollowLogCustomerId(Number(record.key));
		setShowCheckFollowLogModal(true);
	}
	const handleCancelCheckFollowLog = () => {
		setShowCheckFollowLogModal(false)
		setCheckFollowLogCustomerId(0);
	}

	const [checkSpendLogCustomerId, setCheckSpendLogCustomerId] = React.useState<number>(0);
	const [showCheckSpendLogModal, setShowCheckSpendLogModal] = React.useState(false);
	const handleCheckSpendLog = (record: CustomerRow) => {
		setCheckSpendLogCustomerId(Number(record.key));
		setShowCheckSpendLogModal(true);
	}
	const handleCancelCheckSpendLog = () => {
		setShowCheckSpendLogModal(false)
		setCheckSpendLogCustomerId(0);
	}


	const [loading, setLoading] = useState(false);

	const rowSelection = {
		selectedKeys,
		onChange: (selectedKeys: React.Key[], selectedRows: CustomerRow[]) => {
			console.log('selectedRowKeys changed: ', selectedKeys);
			setSelectedKeys(selectedKeys);
			setSelectedRows(selectedRows);
		},
	};

	const columns: ProColumns<CustomerRow>[] = [
		{
			title: '客户编号',
			dataIndex: 'customerNo',
			key: 'customerNo',
			fixed: 'left',
			width: 110
		},
		{
			title: '所属BC',
			key: 'bcNameList',
			width: 150,
			render: (_, record) => {
				return (
					<div>
						{record.bcNameList.map((item, index) => (
							<div key={index}>{item}</div>
						))}
					</div>
				)
			}
		},
		{
			title: '合作类型',
			key: 'cooperationTypeList',
			width: 100,
			render: (_, record) => {
				return record.cooperationTypeList.map((item, index) => (
					<div key={index}>{cooperationTypeToChinese(item)}</div>
				));
			}
		},
		{
			title: '投放类型',
			key: 'placementList',
			width: 150,
			render: (_, record) => {
				if (!record.placementList || record.placementList.length === 0) {
					return '-';
				}

				return record.placementList.map((item, index) => (
					<div key={index}>{placementToChinese(item)}</div>
				));
			}
		},
		{
			title: '客户类型',
			key: 'customerType',
			width: 120,
			render: (_, record) => {
				if (!record.customerType) {
					return '-';
				}

				let className;
				switch (record.customerType) {
					case CustomerType.S:
						className = styles.customerTypeS;
						break;
					case CustomerType.A:
						className = styles.customerTypeA;
						break;
					case CustomerType.B:
						className = styles.customerTypeB;
						break;
					case CustomerType.C:
						className = styles.customerTypeC;
						break;
					case CustomerType.D:
						className = styles.customerTypeD;
						break;
					default:
						className = '';
				}

				return <div className={className}>
					<span>{customerTypeToChinese(record.customerType)}</span>
				</div>;
			}
		},
		{
			title: '合作进度',
			key: 'cooperationStatus',
			width: 124,
			render: (_, record) => {
				let className;
				switch (record.cooperationStatus) {
					case CooperationStatus.PENDING:
						className = styles.dotPending;
						break;
					case CooperationStatus.FAILED:
						className = styles.dotFailed;
						break;
					case CooperationStatus.NO_BC:
						className = styles.dotNoBC;
						break;
					case CooperationStatus.NO_CHARGE:
						className = styles.dotNoCharge;
						break;
					case CooperationStatus.NO_SPEND:
						className = styles.dotNoSpend;
						break;
					case CooperationStatus.SPENDING:
						className = styles.dotSpending;
						break;
					case CooperationStatus.SPEND_ABORT:
						className = styles.dotSpendAbort;
						break;
					case CooperationStatus.END:
						className = styles.dotEnd;
						break;
					default:
						className = '';
				}

				return <div className={styles.cooperationStatus}>
					<div className={className}/>
					<div>{cooperationStatusToChinese(record.cooperationStatus)}</div>
				</div>
			}
		},
		{
			title: '业务状态',
			key: 'bizStatus',
			width: 144,
			render: (_, record) => {
				let className;
				switch (record.bizStatus) {
					case BizStatus.PENDING:
						className = styles.dotPending;
						break;
					case BizStatus.STARTED:
						className = styles.dotStarted;
						break;
					case BizStatus.PAUSED:
						className = styles.dotPause;
						break;
					default:
						className = '';
				}

				return <div className={styles.bizStatus}>
					<div className={className}/>
					<div>{bizStatusToChinese(record.bizStatus)}</div>
				</div>
			}
		},
		{
			title: '合作时间',
			dataIndex: 'cooperationTime',
			key: 'cooperationTime',
			width: 208,
			render: (_, record) => {
				if (!record.cooperationTime) {
					return '-';
				}
				return record.cooperationTime.format("YYYY-MM-DD");
			}
		},
		{
			title: '直客经理',
			dataIndex: 'directManager',
			key: 'directManager',
			width: 100
		},
		{
			title: '客户来源',
			key: 'customerSource',
			width: 100,
			render: (_, record) => {
				return record.customerSource?.name;
			}
		},
		{
			title: '商务姓名',
			key: 'bizManager',
			width: 100,
			render: (_, record) => {
				return record.bizManager?.username;
			}
		},
		{
			title: '投手姓名',
			key: 'advBuyer',
			width: 100,
			render: (_, record) => {
				return record.advBuyer?.username;
			}
		},
		{
			title: '客户背景',
			dataIndex: 'background',
			key: 'background',
			width: 208
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 260,
			render: (_, record) => (
				<Flex align="center" gap="small">
					{(currentUser.userRole === UserRole.ADMIN || currentUser.userRole === UserRole.MEDIA_MANAGER || currentUser.userRole === UserRole.BIZ_MANAGER) &&
						<Button type={"link"} size={"small"} onClick={() => {
							handleUpdateCustomer(record)
						}}>
							编辑
						</Button>
					}
					<Button type={"link"} size={"small"} onClick={() => {
						handleAddLog(record);
					}}>
						填写记录
					</Button>
					<Dropdown
						menu={{
							items: [
								{
									label: "查看日志",
									key: 'checkUpdate',
								},
								{
									label: "查看跟进记录",
									key: 'checkFollow',
								},
								{
									label: "查看投放情况",
									key: 'checkSpend',
								}
							],
							onClick: (e) => {
								if (e.key === 'checkUpdate') {
									handleCheckUpdateLog(record);
								} else if (e.key === 'checkFollow') {
									handleCheckFollowLog(record);
								} else if (e.key === 'checkSpend') {
									handleCheckSpendLog(record);
								}
							}
						}}
						trigger={['click']}
					>
						<Button type={"link"} size={"small"}>
							更多
						</Button>
					</Dropdown>
				</Flex>
			)
		},
	];

	const expandedRowRender = (record: CustomerRow) => {
		const columns: ColumnType<CustomerCompanyRow>[] = [
			{
				title: '主体名称',
				dataIndex: 'name',
				key: 'name',
				width: 240,
			},
			{
				title: '首次下户时间',
				key: 'firstAccountTime',
				width: 280,
				render: (_, companyRecord) => {
					return <DatePicker
						value={companyRecord.firstAccountTime}
						disabled={(companyRecord.firstAccountTime !== undefined && companyRecord.firstAccountTime !== null) || currentUser.userRole === UserRole.BIZ_MANAGER}
						onChange={value => {
							const params: UpdateCompanyFirstAccountTimeReq = {
								companyId: Number(companyRecord.key),
								firstAccountTime: value ? value.startOf('day').format("YYYY-MM-DD HH:mm:ss") : null,
							}
							updateCompanyFirstAccountTime(params)
								.then(_ => {
									message.success("修改首次下户时间成功");
									tableRef.current?.reload();
								})
								.catch(error => {
									message.warning(`修改首次下户时间失败：${error.message}`)
								})
						}}
						needConfirm
					/>
				}
			},
			{
				title: '下户天数',
				key: 'accountPeriod',
				render: (_, companyRecord) => {
					if (!companyRecord.firstAccountTime) {
						return '-';
					}
					const now = dayjs();
					return now.diff(companyRecord.firstAccountTime, 'day') + 1;
				}
			},
		];
		return (
			<div style={{marginLeft: 70}}>
				<Table columns={columns} dataSource={record.companyList} pagination={false}/>
			</div>
		);
	}

	const exportToExcel = () => {
		console.log('columnState', columnState);
		console.log("selectedRows", selectedRows);

		setLoading(true)
		try {
			console.log(columnState?.customerNo.show);
			console.log(columnState?.bcNameList.show);
			console.log(columnState?.cooperationTypeList.show);

			interface ExportRow {
				用户编号?: string,
				所属BC?: string,
				主体名称?: string,
				首次下户时间?: string,
				下户天数?: string,
				合作类型?: string,
				投放类型?: string,
				客户类型?: string,
				合作进度?: string,
				业务状态?: string,
				合作时间?: string,
				直客经理?: string,
				客户来源?: string,
				商务姓名?: string,
				客户背景?: string,
			}

			const dataSource: ExportRow[] = [];
			selectedRows.forEach(row => {
				row.companyList.forEach((company) => {
					let data: ExportRow = {};
					if (columnState === undefined || columnState.customerNo.show) {
						data['用户编号'] = row.customerNo;
					}
					if (columnState === undefined || columnState?.bcNameList.show) {
						data['所属BC'] = row.bcNameList.join('\n');
					}
					data['主体名称'] = company.name;
					data['首次下户时间'] = company.firstAccountTime ? company.firstAccountTime.startOf('day').format("YYYY-MM-DD HH:mm:ss") : '';
					data['下户天数'] = company.firstAccountTime ? (dayjs().diff(company.firstAccountTime, 'day') + 1).toString() : '';
					if (columnState === undefined || columnState?.cooperationTypeList.show) {
						data['合作类型'] = row.cooperationTypeList.map(item => cooperationTypeToChinese(item)).join('\n');
					}
					if (columnState === undefined || columnState.placementList.show) {
						data['投放类型'] = row.placementList.map(item => placementToChinese(item)).join('\n');
					}
					if (columnState === undefined || columnState?.customerType.show) {
						data['客户类型'] = row.customerType ? customerTypeToChinese(row.customerType) : '';
					}
					if (columnState === undefined || columnState?.cooperationStatus.show) {
						data['合作进度'] = row.cooperationStatus ? cooperationStatusToChinese(row.cooperationStatus) : '';
					}
					if (columnState === undefined || columnState.bizStatus.show) {
						data['业务状态'] = row.bizStatus ? bizStatusToChinese(row.bizStatus) : '';
					}
					if (columnState === undefined || columnState?.cooperationTime.show) {
						data['合作时间'] = row.cooperationTime ? row.cooperationTime.format("YYYY-MM-DD") : '';
					}
					if (columnState === undefined || columnState?.directManager.show) {
						data['直客经理'] = row.directManager;
					}
					if (columnState === undefined || columnState.customerSource.show) {
						data['客户来源'] = row.customerSource?.name;
					}
					if (columnState === undefined || columnState?.bizManager.show) {
						data['商务姓名'] = row.bizManager?.username;
					}
					if (columnState === undefined || columnState?.background.show) {
						data['客户背景'] = row.background
					}
					dataSource.push(data);
				})
			})
			console.log("datasource", dataSource);


			// 将表格数据格式化为工作簿
			const workSheet = XLSX.utils.json_to_sheet(dataSource);
			const workBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

			// 生成并下载 Excel 文件
			XLSX.writeFile(workBook, '客户数据.xlsx');
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.searchContainer}>
					<Form
						form={searchForm}
						name="basic"
						layout="horizontal"
						size="middle"
					>
						<Row gutter={64}>
							<Col span={6}>
								<Form.Item<SearchField> label="客户编号" name="customerNo" style={{width: 300}}>
									<Input placeholder="请输入" allowClear={true}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item<SearchField> label="主体名称" name="companyName" style={{width: 300}}>
									<Input placeholder="请输入" allowClear={true}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item<SearchField> label="客户类型" name="customerType" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={customerTypeSelectOptions}/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={64}>
							<Col span={6}>
								<Form.Item<SearchField> label="合作进度" name="cooperationStatus" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={cooperationStatusSelectOptions}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item<SearchField> label="客户来源" name="customerSource" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={customerSourceOptions}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item<SearchField> label="合作类型" name="cooperationType" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={cooperationTypeSelectOptions}/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={64}>
							<Col span={6}>
								<Form.Item<SearchField> label="投放类型" name="placement" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={placementSelectOptions}/>
								</Form.Item> </Col>
							<Col span={6}>
								<Form.Item<SearchField> label="业务状态" name="bizStatus" style={{width: 300}}>
									<Select placeholder="请选择" allowClear={true} options={bizStatusSelectOptions}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item<SearchField> label="直客经理" name="directManager" style={{width: 300}}>
									<Input placeholder="请输入" allowClear={true}/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={64}>
							<Col span={7}>
								<Form.Item<SearchField> label="合作时间" name="cooperationTime" style={{width: 350}}>
									<RangePicker/>
								</Form.Item></Col>
							<Col span={7}>
								<Form.Item<SearchField> label="首次下户时间" name="firstAccountTime" style={{width: 350}}>
									<RangePicker/>
								</Form.Item>
							</Col>
							<Col span={4} offset={6}>
								<Form.Item>
									<Space>
										<Button type="primary" onClick={handleSearch}>查询</Button>
										<Button onClick={handleSearchReset}>重置</Button>
									</Space>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				<div className={styles.tableContainer}>
					<ProTable
						rowSelection={{
							type: 'checkbox',
							alwaysShowAlert: true,
							...rowSelection,
						}}
						columns={columns}
						expandable={{
							expandedRowRender
						}}
						scroll={{x: 1500}}
						search={false}
						columnsState={{
							value: columnState,
							onChange: (newColumnsState) => {
								// 更新列的状态
								setColumnState(newColumnsState);
								console.log('Columns State Updated:', newColumnsState);
							},
						}}
						pagination={{
							defaultPageSize: 10,
							showSizeChanger: true,
							pageSizeOptions: [10, 20, 100, 2000],
							current: currentPage,
							onChange: (page) => {
								setCurrentPage(page);
							},
						}}
						headerTitle={
							<Flex align="center" gap="middle">
								{(currentUser.userRole === UserRole.ADMIN || currentUser.userRole === UserRole.MEDIA_MANAGER) &&
									<Button type="primary" onClick={handleAddCustomer}>
										新增客户
									</Button>
								}
							</Flex>
						}
						footer={() => (
							<Flex align="center" gap="middle">
								<div style={{marginLeft: 10}}>
									<AppstoreFilled style={{color: "#787878"}}/>
									<span style={{marginLeft: 8, color: "#787878"}}>批量操作：</span>
								</div>
								{(currentUser.userRole === UserRole.ADMIN || currentUser.userRole === UserRole.MEDIA_MANAGER) &&
									<Button type={"primary"} onClick={exportToExcel} disabled={!hasSelected} loading={loading}>
										批量导出
									</Button>
								}
							</Flex>
						)}
						request={async (params, sort, filter) => {
							let res = await listCustomer({
								...searchParams,
								pageNumber: params.current ? params.current - 1 : 0,
								pageSize: params.pageSize,
							});

							const data: CustomerRow[] = res.customers.map(item => ({
								key: item.id,
								customerNo: item.customerNo,
								bcNameList: item.bcNameList,
								cooperationTypeList: item.cooperationTypeList,
								placementList: item.placementList,
								customerType: item.customerType,
								cooperationStatus: item.cooperationStatus,
								bizStatus: item.bizStatus,
								cooperationTime: item.cooperationTime ? dayjs(item.cooperationTime) : undefined,
								accountPeriod: 0,
								directManager: item.directManager,
								customerSource: {
									id: item.customerSource.id,
									name: item.customerSource.name
								},
								bizManager: {
									id: item.bizManager.id,
									username: item.bizManager.username
								},
								advBuyer: item.advBuyer ? {
									id: item.advBuyer.id,
									username: item.advBuyer.username
								} : undefined,
								background: item.background,
								companyList: item.companyList.map(company => ({
									key: company.id,
									name: company.name,
									firstAccountTime: company.firstAccountTime ? dayjs(company.firstAccountTime) : undefined,
								}))
							}));
							return {
								data: data,
								success: true,
								total: res.totalCount,
							};
						}}
						actionRef={tableRef}
					/>
				</div>
			</div>
			<CustomerInfoModal
				showModal={showCustomerModal}
				onCancel={handleCancelCustomerModal}
				modalType={customerModalType}
				initialValues={customerInfoInitialValues}
				tableRef={tableRef}
			/>
			<AddLogModal
				showModal={showAddLogModal}
				onCancel={handleCancelAddLogModal}
				initialValues={addLogInitialValues}
			/>
			<CheckUpdateLogModal
				showModal={showCheckUpdateLogModal}
				onCancel={handleCancelCheckUpdateLog}
				customerId={checkUpdateLogCustomerId}
			/>
			<CheckFollowLogModal
				showModal={showCheckFollowLogModal}
				onCancel={handleCancelCheckFollowLog}
				customerId={checkFollowLogCustomerId}
			/>
			<CheckSpendLogModal
				showModal={showCheckSpendLogModal}
				onCancel={handleCancelCheckSpendLog}
				customerId={checkSpendLogCustomerId}
			/>
		</>
	)
}

import axiosInstance from "./axios";

export interface LoginReq {
	username: string;
	password: string;
}

export interface LoginRes {
	token: string;
}

export const login = async (params: LoginReq): Promise<LoginRes> => {
	const response = await axiosInstance.post<LoginRes>(
		'/auth/login',
		params
	)
	return response.data;
}

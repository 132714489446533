import {UserRole} from "../../const/user-role";
import axiosInstance from "../axios";

export interface CurrentUserRes {
	id: number;
	username: string;
	email: string;
	userRole: UserRole;
}

export const getCurrentUser = async (): Promise<CurrentUserRes> => {
	const response = await axiosInstance.get<CurrentUserRes>(
		'/user/current',
	)
	return response.data;
}

export interface ListUserReq {
	username?: string,
	email?: string,
	role?: UserRole,

	pageNumber?: number,
	pageSize?: number,
}

interface UserItem {
	id: number,
	email: string,
	username: string,
	role: UserRole
}

export interface ListUserRes {
	items: UserItem[],
	totalCount: number
}


export const listUser = async (params: ListUserReq): Promise<ListUserRes> => {

	const response = await axiosInstance.get<ListUserRes>(
		'/user/list',
		{
			params
		}
	)
	return response.data;
}


export interface AddUserReq {
	username: string,
	email: string,
	password: string,
	role: string
}

export const addUser = async (params: AddUserReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/user/add',
		params
	)
	return response.data;
}

export interface UpdateUserReq {
	id: number,
	username: string,
	email: string,
	role: string
}

export const updateUser = async (params: UpdateUserReq): Promise<null> => {
	const response = await axiosInstance.post(
		'/user/update',
		params
	)
	return response.data;
}

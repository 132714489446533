import axios from 'axios';
import {message} from "antd";

const baseUrl = window.location.origin === 'http://localhost:3000' ? 'http://localhost:8080' : window.location.origin;

// 创建带有全局配置的 axios 实例
const axiosInstance = axios.create({
	baseURL: baseUrl + "/api",
	paramsSerializer: params => {
		return Object.keys(params)
			.filter(key => params[key] !== undefined && params[key] !== key)
			.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
			.join("&");
	}
});

// 请求拦截器
axiosInstance.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token');
		console.log("Loaded token: ", token);
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		console.log("Request Interceptor error:", error);
		return Promise.reject(error);
	}
);

// 响应拦截器
axiosInstance.interceptors.response.use(
	response => {
		console.log("HTTP response:", response.config.url, response);
		const res = response.data;
		if (res.code === 0) {
			return res;
		} else if (res.code === 40001) {
			localStorage.removeItem("token");
			localStorage.removeItem('currentUser');
			if (response.config.url !== '/auth/login') {
				window.location.href = '/login';
			}
			return Promise.reject(new Error(res.message));
		} else {
			return Promise.reject(new Error(res.message || '未知错误，请联系客服。'));
		}
	},
	error => {
		console.log("HTTP failed:", error);
		if (error.status === 403) {
			console.warn('鉴权错误');
			window.location.href = '/login';
		} else if (error.status === 500) {
			console.warn('服务端错误:', error);
			message.warning("服务端错误");
		} else {
			console.warn('未知错误:', error);
			message.warning("服务端错误");
		}
	}
);

export default axiosInstance;

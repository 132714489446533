import {Col, Divider, Flex, Form, Input, message, Modal, Row, Select} from "antd";
import styles from "./customer-info-modal.module.scss";
import {DeleteFilled, PlusCircleFilled} from "@ant-design/icons";
import React, {useContext, useEffect, useState} from "react";
import {BC_NAME_LIST} from "./constant";
import {BizStatus, bizStatusSelectOptions} from "../../const/biz-status";
import {CooperationStatus, cooperationStatusSelectOptions} from "../../const/cooperation-status";
import {CooperationType, cooperationTypeSelectOptions} from "../../const/cooperation-type";
import {Placement, placementSelectOptions} from "../../const/placement";
import {CustomerType, customerTypeSelectOptions} from "../../const/customer-type";
import {listUser} from "../../api/modules/user";
import {UserRole} from "../../const/user-role";
import {listCustomerSource} from "../../api/modules/customer-source";
import {useForm} from "antd/es/form/Form";
import {addCustomer, AddCustomerReq, updateCustomer, UpdateCustomerReq} from "../../api/modules/customer";
import {ActionType} from "@ant-design/pro-table/es/typing";
import {RootContext} from "../root/root";


const {TextArea} = Input;

type CustomerFormField = {
	customerId: number;
	customerNo: string; // 客户编号
	bcNameList: string[]; // 所属BC，选择多个
	companyList: {
		companyId: number | null;
		companyName: string;
	}[]; // 主体名称可以有多个
};

type CooperationFormField = {
	cooperationTypeList: CooperationType[]; // 合作类型
	placementList?: Placement[]; // 投放类型
	customerType?: CustomerType; // 客户类型
	directManager: string; // 直客经理
	customerSource: number; // 客户来源
	bizManager: number; // 商务姓名
	advBuyer: number; // 投手姓名
	cooperationStatus: CooperationStatus; // 合作进度
	bizStatus: BizStatus; // 业务状态
	background?: string; // 客户背景
};

export interface IModalCustomerInfo {
	// 类型为add时，customerId不生效
	customerId?: number;
	customerNo?: string;
	bcNameList?: string[],
	companyList: {
		companyId: number | null;
		companyName: string
	}[],
	cooperationTypeList?: CooperationType[],
	placementList?: Placement[],
	customerType?: CustomerType,
	directManager?: string,
	customerSource?: number,
	bizManager?: number,
	advBuyer?: number,
	cooperationStatus?: CooperationStatus,
	bizStatus?: BizStatus,
	background?: string
}

const defaultInitialValues: IModalCustomerInfo = {
	customerId: undefined,
	customerNo: undefined,
	bcNameList: [],
	companyList: [{
		companyId: null,
		companyName: ''
	}],
	cooperationTypeList: undefined,
	placementList: undefined,
	customerType: undefined,
	directManager: undefined,
	customerSource: undefined,
	bizManager: undefined,
	advBuyer: undefined,
	cooperationStatus: undefined,
	bizStatus: undefined,
	background: undefined,
}

const bcNameListOptions = BC_NAME_LIST.map((item: string) => (
	{
		label: item,
		value: item
	}
))

export default function CustomerInfoModal(
	{
		showModal,
		onCancel,
		modalType,
		initialValues,
		tableRef
	}: {
		showModal: boolean;
		onCancel: () => void;
		modalType: 'add' | 'update';
		initialValues?: IModalCustomerInfo;
		tableRef: React.MutableRefObject<ActionType | undefined>;
	}
) {
	const {currentUser} = useContext(RootContext);

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [customerForm] = useForm<CustomerFormField>();
	const [cooperationForm] = useForm<CooperationFormField>();

	const onOk = async () => {
		try {
			const [customerFormValues, cooperationFormValues] = await Promise.all([
				customerForm.validateFields(),
				cooperationForm.validateFields(),
			]);
			console.log("Form1 values: ", customerFormValues);
			console.log("Form2 values: ", cooperationFormValues);

			setConfirmLoading(true);
			if (modalType === 'add') {
				let params: AddCustomerReq = {
					customerNo: customerFormValues.customerNo,
					bcNameList: customerFormValues.bcNameList,
					companyNameList: customerFormValues.companyList.map((item) => (item.companyName)),
					cooperationTypeList: cooperationFormValues.cooperationTypeList,
					placementList: cooperationFormValues.placementList,
					customerType: cooperationFormValues.customerType,
					directManager: cooperationFormValues.directManager,
					customerSourceId: cooperationFormValues.customerSource,
					bizManagerId: cooperationFormValues.bizManager,
					advBuyerId: cooperationFormValues.advBuyer,
					cooperationStatus: cooperationFormValues.cooperationStatus,
					bizStatus: cooperationFormValues.bizStatus,
					background: cooperationFormValues.background
				}
				addCustomer(params)
					.then((_) => {
						message.success('添加客户成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("添加客户失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});

			} else {
				let params: UpdateCustomerReq = {
					customerId: customerFormValues.customerId,
					customerNo: customerFormValues.customerNo,
					bcNameList: customerFormValues.bcNameList,
					companyList: customerFormValues.companyList.map((item) => {
						return {
							companyId: item.companyId,
							companyName: item.companyName
						}
					}),
					cooperationTypeList: cooperationFormValues.cooperationTypeList,
					placementList: cooperationFormValues.placementList,
					customerType: cooperationFormValues.customerType ? cooperationFormValues.customerType : null,
					directManager: cooperationFormValues.directManager,
					customerSourceId: cooperationFormValues.customerSource,
					bizManagerId: cooperationFormValues.bizManager,
					advBuyerId: cooperationFormValues.advBuyer,
					cooperationStatus: cooperationFormValues.cooperationStatus,
					bizStatus: cooperationFormValues.bizStatus,
					background: cooperationFormValues.background
				}
				updateCustomer(params)
					.then((_) => {
						message.success('编辑客户成功');
						tableRef.current?.reload()
						onCancel();
					})
					.catch(error => {
						message.warning("编辑客户失败: " + error.message);
					})
					.finally(() => {
						setConfirmLoading(false);
					});
			}

		} catch (error) {
			console.log("Validation failed:", error);
		}
	}

	useEffect(() => {
		if (showModal) {
			customerForm.setFieldsValue(initialValues || defaultInitialValues);
			cooperationForm.setFieldsValue(initialValues || defaultInitialValues);
		}
	}, [showModal, initialValues, customerForm, cooperationForm]);

	const [bizManagerOptions, setBizManagerOptions] = useState<{
		label: string,
		value: number
	}[]>([]);

	const [advBuyerOptions, setAdvBuyerOptions] = useState<{
		label: string,
		value: number
	}[]>([]);

	const [customerSourceOptions, setCustomerSourceOptions] = useState<{
		label: string,
		value: number
	}[]>([]);

	useEffect(() => {
		// 获取所有商务
		listUser({
			role: UserRole.BIZ_MANAGER
		}).then((response) => {
			setBizManagerOptions(response.items.map(item => {
				return {
					label: item.username,
					value: item.id,
				}
			}))
		})
		// 获取所有商务
		listUser({
			role: UserRole.ADV_BUYER
		}).then((response) => {
			setAdvBuyerOptions(response.items.map(item => {
				return {
					label: item.username,
					value: item.id,
				}
			}))
		})
		// 获取所有客户来源
		listCustomerSource({}).then((response) => {
			setCustomerSourceOptions(response.items.map(item => {
				return {
					label: item.name,
					value: item.id,
				}
			}))
		})
	}, []);

	return (
		<Modal
			title={modalType === 'add' ? '添加客户' : '编辑客户'}
			open={showModal}
			onOk={onOk}
			onCancel={onCancel}
			confirmLoading={confirmLoading}
			destroyOnClose={true}
			width={728}
		>
			<Divider/>
			<div className={styles.container}>
				<div className={styles.regionTitle}>客户信息</div>
				<Form
					form={customerForm}
					layout={"vertical"}
					initialValues={initialValues}
				>
					<Form.Item<CustomerFormField> name="customerId" style={{display: 'none'}}>
						<Input/>
					</Form.Item>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item<CustomerFormField>
								label="客户编号"
								name="customerNo"
								rules={[{
									required: true,
									message: "请输入客户编号"
								}]}
							>
								<Input disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item<CustomerFormField>
								label="所属BC"
								name="bcNameList"
								rules={[{
									required: true,
									message: "请选择所属BC"
								}]}
							>
								<Select
									mode="multiple"
									allowClear
									options={bcNameListOptions}
									disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item<CustomerFormField> label={"主体名称"} required={true}>
								<Form.List name="companyList">
									{(fields, {add, remove}) => {
										return (
											(
												<>
													{fields.map((field, index, fieldKey, ...restField) => {
														return (
															<Flex key={index} align="baseline">
																<Form.Item name={[field.name, 'companyId']} style={{display: "none"}}>
																	<Input/>
																</Form.Item>
																<Form.Item
																	name={[field.name, 'companyName']}
																	rules={[{
																		required: true,
																		message: '请输入主体名称'
																	}]}
																	style={{
																		marginBottom: 12,
																		width: '100%'
																	}}
																>
																	<Input disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
																</Form.Item>
																{currentUser.userRole === UserRole.BIZ_MANAGER
																	? null
																	: fields.length < 9999
																		? <PlusCircleFilled onClick={() => add()} className={styles.addIcon}/>
																		: <PlusCircleFilled className={styles.addIconNotAllowed}/>
																}
																{currentUser.userRole === UserRole.BIZ_MANAGER
																	? null
																	: fields.length > 1
																		? <DeleteFilled onClick={() => remove(field.name)} className={styles.deleteIcon}/>
																		: <DeleteFilled className={styles.deleteIconNotAllowed}/>
																}
															</Flex>
														)
													})}
												</>
											)
										)
									}}
								</Form.List>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<div className={styles.regionTitle}>合作信息</div>
				<Form
					form={cooperationForm}
					layout={"vertical"}
					initialValues={initialValues}
					onValuesChange={(changedValues, values) => {
						// 1）合作进度“消耗中”对应业务状态“已启动”
						// 2）合作进度“消耗中断”对应业务状态“暂无/停止计划”
						// 3）合作进度“结束合作”对应业务状态“暂无/停止计划”
						if (changedValues.hasOwnProperty("cooperationStatus")) {
							const targetCooperationStatus = changedValues.cooperationStatus;
							let targetBizStatus;
							if (targetCooperationStatus === CooperationStatus.SPENDING) {
								targetBizStatus = BizStatus.STARTED;
							} else if (targetCooperationStatus === CooperationStatus.SPEND_ABORT || targetCooperationStatus === CooperationStatus.END) {
								targetBizStatus = BizStatus.PAUSED;
							} else {
								targetBizStatus = BizStatus.PENDING;
							}

							cooperationForm.setFieldsValue({
								bizStatus: targetBizStatus,
							})
						}
					}}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label="合作类型"
								name="cooperationTypeList"
								rules={[{
									required: true,
									message: "请选择合作类型"
								}]}
							>
								<Select
									mode="multiple"
									options={cooperationTypeSelectOptions}
									disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="投放类型"
								name="placementList"
							>
								<Select
									mode="multiple"
									options={placementSelectOptions}
									allowClear={true}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label="客户类型"
								name="customerType"
							>
								<Select options={customerTypeSelectOptions} allowClear={true}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="直客经理"
								name="directManager"
								rules={[{
									required: true,
									message: "请输入直客经理"
								}]}
							>
								<Input disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label="客户来源"
								name="customerSource"
								rules={[{
									required: true,
									message: "请选择客户来源"
								}]}
							>
								<Select options={customerSourceOptions}
												disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="商务姓名"
								name="bizManager"
								rules={[{
									required: true,
									message: "请选择商务姓名"
								}]}
							>
								<Select options={bizManagerOptions}
												disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label="投手姓名"
								name="advBuyer"
							>
								<Select options={advBuyerOptions}
												disabled={modalType === 'update' && currentUser.userRole !== UserRole.ADMIN}
												allowClear={true}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="合作进度"
								name="cooperationStatus"
								rules={[{
									required: true,
									message: "请选择合作进度"
								}]}
							>
								<Select
									options={cooperationStatusSelectOptions}
									disabled={modalType === 'update' && currentUser.userRole === UserRole.BIZ_MANAGER}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								label="业务状态"
								name="bizStatus"
								rules={[{
									required: true,
									message: "请选择业务状态"
								}]}
							>
								<Select options={bizStatusSelectOptions} disabled/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item label="客户背景" name="background">
						<TextArea rows={3}/>
					</Form.Item>
				</Form>
			</div>
		</Modal>
	)
}

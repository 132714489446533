import React from 'react';
import './App.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Login from "./routes/login/login";
import {ConfigProvider} from 'antd';
import Dashboard from "./routes/dashboard/dashboard";
import Customer from "./routes/customer/customer";
import RootLayout from "./routes/root/root";
import CustomerSource from "./routes/config/customer-source/customer-source";
import User from "./routes/config/user/user";
import Error from "./routes/error/error";
import Unauthorized from "./routes/unauthorized/unauthorized";
import {UserRole} from "./const/user-role";
import zhCN from 'antd/locale/zh_CN';

const checkUserPermission = (requiredRole: UserRole) => {
	const data = localStorage.getItem("currentUser");
	if (data === null) {
		return false;
	}

	const user = JSON.parse(data);
	return user && user.userRole === requiredRole;
};

interface PrivateRouteProps {
	children: React.ReactNode;
	requiredRole: UserRole;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children, requiredRole}) => {
	return checkUserPermission(requiredRole) ? <>{children}</> : <Navigate to="/unauthorized"/>;
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <RootLayout/>,
		children: [
			{
				path: "/dashboard",
				element: <Dashboard/>,
			},
			{
				path: "/customer",
				element: <Customer/>,
			},
			{
				path: "/config",
				children: [
					{
						path: '/config/customer_source',
						element: (
							<PrivateRoute requiredRole={UserRole.ADMIN}>
								<CustomerSource/>
							</PrivateRoute>
						)
					},
					{
						path: '/config/user',
						element: (
							<PrivateRoute requiredRole={UserRole.ADMIN}>
								<User/>
							</PrivateRoute>
						)
					}
				]
			}
		]
	},
	{
		path: "/login",
		element: <Login/>,
	},
	{
		path: "/error",
		element: <Error/>,
	},
	{
		path: "/unauthorized",
		element: <Unauthorized/>,
	}
]);

function App() {
	return (
		<ConfigProvider locale={zhCN} theme={{}}>
			<RouterProvider router={router}/>
		</ConfigProvider>
	);
}

export default App;
